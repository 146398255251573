import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import { WebchatContextProvider,  } from "./context/WebchatContext";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <WebchatContextProvider>
        <App style={{ zIndex: 20000 }} />
      </WebchatContextProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("chat-g4")
);
