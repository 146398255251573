import styled from "styled-components";

export const MessageImageStyle = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(${(props) => props.src});
  height: 240px;
  width: 240px;
  padding: 10px;
  border-radius: 5px;

  @media screen and (min-width: 1440px) {
    height: ${(props) => props.modeWebchatFull ? '400px' : 'none'};
    width: ${(props) => props.modeWebchatFull ? '400px' : 'none'};
  }

  #date_G4_flex {
    font-size: 10px;
    margin-top: 10px;
    text-align: right;
    margin-left: 5px;
    font-family: roboto;
  }
`;
