import React from "react";
import { MessageImageStyle, MessageVideoStyle } from "./style";
import { useSelector } from "react-redux";
import {
  G4Cliente,
  G4Wait,
  G4Atendente,
} from "../../../pages/conversation/components/styled";
import { Hours } from "../../styled";
import { useContext } from "react";
import { WebchatContext } from "../../../context/WebchatContext";

const Message = ({ time, source, user }) => {
  const config = useSelector((state) => state.config);
  const {modeWebchatFull} = useContext(WebchatContext);

  const content = (
    <>
      <MessageVideoStyle modeWebchatFull={modeWebchatFull} src={source}>
        <video width="255" height="240" controls>
          <source src={source} type="video/mp4"/>
        </video>
      </MessageVideoStyle>
                 
      <Hours>
      {new Date(time).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })}
      </Hours>
    </>
  );

  return (
    <>
      {user === "cliente" && (
        <G4Cliente isVideo color={config.color}>{content}</G4Cliente>
      )}
      {user === "atendente" && <G4Atendente>{content}</G4Atendente>}
      {user === "wait" && <G4Wait color={config.color}>{content}</G4Wait>}
    </>
  );
};

export default Message;
