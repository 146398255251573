import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import "./App.css";
import "./App.scss";
import { G4Container, G4FullWindow, G4LogoOpen, Switch } from "./components/styled";
import "./config/ReactotronConfig";
import Header from "./components/header/Header";
import Login from "./pages/login/Login";
import Queue from "./pages/queue/Queue";
import Wait from "./pages/wait/Wait";
import Conversation from "./pages/conversation/Conversation";
import CustumerExperienceSurvey from "./pages/customer-experience-survey/CustumerExperienceSurvey";
import End from "./pages/end/End";
import { FaComments } from "react-icons/fa";

import { WebchatContext } from "./context/WebchatContext"; 

import "./services/get-config-chat";
import { subscribe } from "./util/events";
import { G4LoginImg, G4LoginImgDiv } from "./pages/login/components/styled";
import { getLogoChatURL } from "./components/logos";

const LOGIN = "Login";
const QUEUE = "Queue";
const WAIT = "Wait";
const CONVERSATION = "Conversation";
const CUSTOMER_EXPERIENCE = "CustomerExperience";
const END = "End";

const App = () => {
  const page = useSelector((state) => state.page);
  const config = useSelector((state) => state.config);
  const [chatOpen, setChatOpen] = useState(true);
  const [logoURL, setLogoURL] = useState(null);
  const { modeWebchatFull } = useContext(WebchatContext)
  
  subscribe("openWebChat", () => openChat());

  function openChat() {
    setChatOpen(true);
  }

  function minimize(value) {
    setChatOpen(value);
  }

  useEffect(() => {
    getLogoChatURL()
      .then((source) => {
        setLogoURL(source);
      })
      .catch(() => console.error("[LOGIN FORM] Error loading chat logo"));
  }, []);

  return (  
    <>
      {/* <Switch color={config.color} >
        <input type="checkbox" checked={modeWebchatFull} onChange={handleToggle}/>
        <span class="slider round"></span>
      </Switch> */}
      {modeWebchatFull ? (
        <G4FullWindow>
          <G4LoginImgDiv>
            <G4LoginImg src={logoURL} alt="logo" />
          </G4LoginImgDiv>
        
          <G4Container page={page} modeWebchatFull={modeWebchatFull} style={{ zIndex: 20000 }} size={chatOpen}>
            <Header />
            {page === LOGIN ? <Login /> : ""}
            {page === QUEUE ? <Queue /> : ""}
            {page === WAIT ? <Wait /> : ""}
            {page === CONVERSATION ? (
              <Conversation size={chatOpen ? "" : "none"}/>
            ) : (
              ""
            )}
            {page === CUSTOMER_EXPERIENCE ? <CustumerExperienceSurvey /> : ""}
            {page === END ? <End /> : ""}
          </G4Container>
        </G4FullWindow>
      ) : (
        <div style={{ zIndex: 20000 }}>
          {!chatOpen && config.color !== "" && (
            <G4LogoOpen
              color={config.color}
              style={{ zIndex: 20000 }}
              onClick={() => {
                openChat();
              }}
            >
              <FaComments
                onClick={() => {
                  openChat();
                }}
                size="40"
                color="#fff"
              />
            </G4LogoOpen>
          )}

          <G4Container style={{ zIndex: 20000 }} size={chatOpen}>
            <Header minimize={minimize} />
            {page === LOGIN ? <Login /> : ""}
            {page === QUEUE ? <Queue /> : ""}
            {page === WAIT ? <Wait /> : ""}
            {page === CONVERSATION ? (
              <Conversation size={chatOpen ? "" : "none"} />
            ) : (
              ""
            )}
            {page === CUSTOMER_EXPERIENCE ? <CustumerExperienceSurvey /> : ""}
            {page === END ? <End /> : ""}
          </G4Container>
        </div>
      )}
    </>
  )
};

export default App;
